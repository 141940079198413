import { useCallback, useEffect, useRef, useState } from "react";
import {
	Table,
	Dropdown,
	Menu,
	MenuProps,
	Modal,
	Spin,
	Typography,
	Pagination,
	Upload,
	Button,
} from "antd";
import { ErrorCodes, SimpleViewStudentDTO, StudentYearEnum } from "../../Api";
import {
	downloadStudentCv,
	getAllStudentsV2,
	getStudentFilters,
	toggleOverdueStudentPractice,
	uploadStudentCvAdmin,
} from "../../Requests/academic-user-requests";
import { useHistory, useLocation } from "react-router-dom";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { studentIdSet } from "../Student/StudentSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	useIsAdmin,
	useIsInternshipAdmin,
	useIsPoliJobsAdmin,
	useIsRector,
} from "../../utils/utilFunctions";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import { useQuery, useQueryClient } from "react-query";
import {
	getStudents,
	getStudentsFilters,
} from "../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../utils/responseUtils";
import Filters from "../Filters";
import useQueryFilters from "../../Hooks/useQueryFilters";
import { downloadFile } from "../../utils/downloadUtils";
import styles from "./Students.module.scss";
import PoliJobsContactDetailsModal from "./PoliJobsContactDetailsModal";

enum ActionKeyEnum {
	ViewLinkedInAccount,
	ViewCV,
	MarkOverdueL2Practice,
	RemoveOverdueL2Practice,
	MarkOverdueL3Practice,
	RemoveOverdueL3Practice,
	EditContactDetails,
	UploadCV,
}

const StudentsTable = () => {
	const { t, i18n } = useTranslation();
	const isAdmin = useIsAdmin();
	const isRector = useIsRector();
	const isInternshipAdmin = useIsInternshipAdmin();
	const isPoliJobsAdmin = useIsPoliJobsAdmin();
	const [modalTitle, setModalTitle] = useState("");
	const [modalText, setModalText] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isContactDetailsModalVisible, setIsContactDetailsModalVisible] =
		useState(false);
	const [handleFunction, setHandleFunction] = useState(() => () => {});
	const [spinning, setSpinning] = useState(false);
	const [activeTab, setActiveTab] = useState("2");
	const [contactDetailsModalType, setContactDetailsModalType] = useState("add");
	const dispatch = useDispatch();
	const history = useHistory();
	const { search } = useLocation();
	const queryClient = useQueryClient();
	const query = useQueryFilters({});
	const [studentId, setStudentId] = useState("");
	const [contactDetailsStudentId, setContactDetailsStudentId] = useState("add");
	const uploadRef = useRef<HTMLButtonElement>(null);

	let locale = {
		emptyText: t("tableText.noStudents"),
	};

	useEffect(() => {
		const url = new URLSearchParams(search);
		if (!url) return;

		for (const [key, value] of url) {
			if (key === "tab") {
				setActiveTab(value);
				break;
			}
		}
	}, []);

	useEffect(() => {
		if (studentId && uploadRef.current) {
			uploadRef.current.click();
		}
	}, [studentId]);

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const toggleOverduePractice = (studentId: string, year: StudentYearEnum) => {
		setSpinning(true);

		toggleOverdueStudentPractice(studentId, year)
			.then(async () => {
				openNotification(
					t("students.updatePracticeOverdueStatus"),
					t("students.updatePracticeOverdueStatusSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);

				await queryClient.invalidateQueries(getStudents);
				await queryClient.invalidateQueries(getStudentsFilters);
			})
			.catch(async (error) => {
				const errorMessage = await getErrorFromResponse(error);

				let notificationMessage;

				switch (errorMessage?.code) {
					case ErrorCodes.UserNotFound:
						notificationMessage = t("students.studentNotFound");
						break;
					case ErrorCodes.Forbidden:
						notificationMessage = t("students.notAllowed");
						break;
					default:
						notificationMessage = t(
							"students.updatePracticeOverdueStatusError"
						);
						break;
				}

				openNotification(
					t("students.updatePracticeOverdueStatus"),
					notificationMessage,
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const handleClose = () => {
		setIsModalVisible(false);
	};

	const getActions = (record: SimpleViewStudentDTO): ItemType[] | undefined => {
		let actions: MenuProps["items"] = [];

		actions.push(
			{
				label: record.cvUrl ? t("students.cvUrl") : t("students.downloadCv"),
				key: ActionKeyEnum.ViewCV + "StudentsTables",
				icon: (
					<FontAwesomeIcon
						icon={record.cvUrl ? solid("arrow-right") : solid("download")}
						style={{ fontSize: "15px" }}
					/>
				),
			},
			{
				label: t("students.viewLinkedIn"),
				key: ActionKeyEnum.ViewLinkedInAccount + "StudentsTables",
				icon: (
					<FontAwesomeIcon
						icon={solid("arrow-right")}
						style={{ fontSize: "15px" }}
					/>
				),
			}
		);

		if (
			isInternshipAdmin &&
			(record.year === StudentYearEnum.L3 ||
				record.year === StudentYearEnum.L4) &&
			record.facultyNameRo === "Facultatea de Inginerie Industrială și Robotică"
		) {
			if (!record.hasOverdueL2Practice) {
				actions.push({
					label: t("students.markOverdueL2Practice"),
					key: ActionKeyEnum.MarkOverdueL2Practice + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("circle-xmark")}
							style={{ fontSize: "15px" }}
						/>
					),
				});
			} else {
				actions.push({
					label: t("students.removeOverdueL2Practice"),
					key: ActionKeyEnum.RemoveOverdueL2Practice + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("circle-check")}
							style={{ fontSize: "15px" }}
						/>
					),
				});
			}
		}

		if (
			isInternshipAdmin &&
			(record.year === StudentYearEnum.L3 || record.year === StudentYearEnum.L4)
		) {
			if (!record.hasOverdueL3Practice) {
				actions.push({
					label: t("students.markOverdueL3Practice"),
					key: ActionKeyEnum.MarkOverdueL3Practice + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("circle-xmark")}
							style={{ fontSize: "15px" }}
						/>
					),
				});
			} else {
				actions.push({
					label: t("students.removeOverdueL3Practice"),
					key: ActionKeyEnum.RemoveOverdueL3Practice + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("circle-check")}
							style={{ fontSize: "15px" }}
						/>
					),
				});
			}
		}

		if (isPoliJobsAdmin) {
			actions.push(
				{
					label: t("students.editContactDetails"),
					key: ActionKeyEnum.EditContactDetails + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("edit")}
							style={{ fontSize: "15px" }}
						/>
					),
				},
				{
					label: t("students.uploadCv"),
					key: ActionKeyEnum.UploadCV + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("upload")}
							style={{ fontSize: "15px" }}
						/>
					),
				}
			);
		}

		return actions;
	};

	const downloadFail = (_err: any) => {
		openNotification(
			t("universityAccord.error"),
			t("universityAccord.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const onMenuClick = (e: any, record: SimpleViewStudentDTO) => {
		if (e.key == ActionKeyEnum.ViewCV + "StudentsTables") {
			if (record.cvUrl) {
				window.location.replace(record.cvUrl!);
			} else if (record.cvFileName) {
				downloadFile(
					downloadStudentCv(record.id!),
					t("documents.Cv") + "_" + record.name
				).catch(downloadFail);
			}
		} else if (e.key == ActionKeyEnum.ViewLinkedInAccount + "StudentsTables") {
			window.location.replace(record.linkedinUrl!);
		} else if (
			e.key ==
			ActionKeyEnum.MarkOverdueL2Practice + "StudentsTables"
		) {
			setModalTitle(t("students.markOverdueL2Practice"));
			setModalText(
				t("students.markOverdueL2PracticeMessage") + record.name + "?"
			);
			setIsModalVisible(true);
			setHandleFunction(
				() => () => toggleOverduePractice(record.id!, StudentYearEnum.L2)
			);
		} else if (
			e.key ==
			ActionKeyEnum.RemoveOverdueL2Practice + "StudentsTables"
		) {
			setModalTitle(t("students.removeOverdueL2Practice"));
			setModalText(
				t("students.removeOverdueL2PracticeMessage") + record.name + "?"
			);
			setIsModalVisible(true);
			setHandleFunction(
				() => () => toggleOverduePractice(record.id!, StudentYearEnum.L2)
			);
		} else if (
			e.key ==
			ActionKeyEnum.MarkOverdueL3Practice + "StudentsTables"
		) {
			setModalTitle(t("students.markOverdueL3Practice"));
			setModalText(
				t("students.markOverdueL3PracticeMessage") + record.name + "?"
			);
			setIsModalVisible(true);
			setHandleFunction(
				() => () => toggleOverduePractice(record.id!, StudentYearEnum.L3)
			);
		} else if (
			e.key ==
			ActionKeyEnum.RemoveOverdueL3Practice + "StudentsTables"
		) {
			setModalTitle(t("students.removeOverdueL3Practice"));
			setModalText(
				t("students.removeOverdueL3PracticeMessage") + record.name + "?"
			);
			setIsModalVisible(true);
			setHandleFunction(
				() => () => toggleOverduePractice(record.id!, StudentYearEnum.L3)
			);
		} else if (e.key == ActionKeyEnum.EditContactDetails + "StudentsTables") {
			setContactDetailsModalType(
				record.hasPoliJobsContactDetails ? "edit" : "add"
			);
			setContactDetailsStudentId(record.id!);
			setIsContactDetailsModalVisible(true);
		} else if (e.key == ActionKeyEnum.UploadCV + "StudentsTables") {
			setHandleFunction(() => {
				setStudentId(record.id!);
			});
		}
	};

	const columns = [
		{
			title: t("account.name"),
			dataIndex: "name",
			fixed: "left" as "left",
			// sorter: (a: SimpleViewStudentDTO, b: SimpleViewStudentDTO) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.id)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("students.faculty"),
			dataIndex: "facultyNameRo",
			key: "facultyNameRo",
			hidden: (!isAdmin && !isRector) || window.innerWidth < 650,
			// sorter: (a: SimpleViewStudentDTO, b: SimpleViewStudentDTO) => {
			// 	if (i18n.language === "ro") {
			// 		if (!a.facultyNameRo || !b.facultyNameRo) return -1;
			// 		return a.facultyNameRo.localeCompare(b.facultyNameRo);
			// 	} else {
			// 		if (!a.facultyNameEn || !b.facultyNameEn) return -1;
			// 		return a.facultyNameEn.localeCompare(b.facultyNameEn);
			// 	}
			// },
		},
		{
			title: t("students.studyProgram"),
			dataIndex: "studyProgram",
			key: "studyProgram",
			hidden: isAdmin || isRector,
			// sorter: (a: SimpleViewStudentDTO, b: SimpleViewStudentDTO) => {
			// 	if (!a.studyProgram || !b.studyProgram) return -1;
			// 	return a.studyProgram.localeCompare(b.studyProgram);
			// },
		},
		{
			title: t("students.year"),
			dataIndex: "year",
			key: "year",
			// sorter: (a: SimpleViewStudentDTO, b: SimpleViewStudentDTO) => {
			// 	if (!a.year || !b.year) return -1;
			// 	return a.year.localeCompare(b.year);
			// },
		},
		{
			title: t("students.actions"),
			dataIndex: "actions",
			fixed: "right" as "right",
			width: 85,
			render: (_text: string | undefined, record: any) => (
				<div>
					<Dropdown
						placement="bottomLeft"
						overlay={
							<Menu
								onClick={(e) => onMenuClick(e, record)}
								items={getActions(record)}
							/>
						}
					>
						<FontAwesomeIcon
							icon={solid("bars")}
							style={{
								cursor: "pointer",
								height: "1rem",
								padding: "6px 0px",
								marginLeft: "13px",
							}}
						/>
					</Dropdown>
				</div>
			),
		},
	].filter((item) => !item.hidden);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.failedUserGet"),
			t("usersText.errorTexts.serverFailedDescription"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data, isLoading: loading } = useQuery(
		[getStudents, query.filters],
		() => {
			return getAllStudentsV2(query.filters);
		},
		{
			onError: openGetErrorNotification,
		}
	);

	const { data: filters } = useQuery(
		[getStudentsFilters, i18n.language],
		() => {
			return getStudentFilters(i18n.language);
		},
		{
			onError: openGetErrorNotification,
		}
	);

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update({ tab: [activeTab], ...values }, true);
		},
		[query]
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	const returnAction = t("polijobs.uploadedFile");

	const uploadSucceeded = () => {
		openNotification(
			t("polijobs.uploadedFile"),
			t("polijobs.uploadSuccess"),
			NOTIFICATION_TYPES.SUCCESS
		);
	};

	const uploadFailed = (_err: any) => {
		openNotification(
			t("polijobs.error"),
			t("polijobs.uploadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	return (
		<div className="px-4 pb-10 flex flex-col">
			<Filters
				filters={filters}
				searchFields={[{ name: "search", label: t("search.byName") }]}
				onUpdate={onUpdate}
				hasSort={false}
			/>

			<div className="flex flex-col gap-10">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{data?.totalCount} {t("filtering.results")}
					</Typography.Title>
					{!loading && (
						<Pagination
							defaultCurrent={data?.page}
							defaultPageSize={data?.pageSize}
							total={data?.totalCount}
							onChange={onPageUpdate}
							locale={{ items_per_page: t("pagination") }}
						/>
					)}
				</div>

				<Table
					locale={locale}
					columns={columns}
					dataSource={data?.data ?? []}
					pagination={false}
					scroll={{ x: "calc(700px + 50%)", y: 420 }}
					rowKey={(record) => record.id!}
				/>
			</div>
			<Modal
				open={isModalVisible}
				onOk={handleFunction}
				onCancel={handleClose}
				title={modalTitle}
				width={
					window.innerWidth > 800
						? "35%"
						: window.innerWidth < 450
						? "80%"
						: "70%"
				}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={handleFunction}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<div>
						<Typography>{modalText}</Typography>
					</div>
				</Spin>
			</Modal>
			<PoliJobsContactDetailsModal
				type={contactDetailsModalType}
				studentId={contactDetailsStudentId}
				visible={isContactDetailsModalVisible}
				setVisible={setIsContactDetailsModalVisible}
				onSuccess={async () => {
					await queryClient.invalidateQueries(getStudents);
					await queryClient.invalidateQueries(getStudentsFilters);
				}}
				onClose={() => {
					setContactDetailsModalType("add");
					setContactDetailsStudentId("");
				}}
			/>
			<div className={styles.modalBtnContainer}>
				<Upload
					accept=".pdf"
					action={(uploaded) => {
						uploadStudentCvAdmin(studentId, uploaded)
							.then(() => {
								setStudentId("");
								return uploadSucceeded();
							})
							.catch((error) => {
								setStudentId("");
								return uploadFailed(error);
							});
						return returnAction;
					}}
					showUploadList={false}
					multiple={false}
					customRequest={({ onSuccess }) => {
						onSuccess && onSuccess("");
					}}
				>
					<Button
						ref={uploadRef}
						style={{
							display: "none",
						}}
					></Button>
				</Upload>
			</div>
		</div>
	);
};

export default StudentsTable;
