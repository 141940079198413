import {
	AcademicUserApi,
	AcademicUserUpdateDTO,
	StudentYearEnum,
	AcademicUserUpdateRoleDTO,
} from "../Api";
import { arrayParamAjust } from "../utils/dataUtils";
import { getAuthConfiguration } from "./configuration";

const AuthAcademicUserApiFactory = () =>
	new AcademicUserApi(getAuthConfiguration());

//de testat toate
export const getAllAcademicUsers = (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	faculties?: string[]
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAllAcademicUsersGet({
		namePattern,
		faculties: arrayParamAjust(faculties),
		page,
		pageSize,
	});
};

export const getAllStudents = (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	years?: string[],
	faculties?: string[],
	departments?: string[],
	studyPrograms?: string[]
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAllStudentsGet({
		namePattern,
		years: arrayParamAjust(
			years?.map((e) => StudentYearEnum[e as keyof typeof StudentYearEnum])
		),
		faculties: arrayParamAjust(faculties),
		departments: arrayParamAjust(departments),
		studyPrograms: arrayParamAjust(studyPrograms),
		page,
		pageSize,
	});
};

export interface AllStudentsProps {
	search?: string; //namePattern
	page?: number; //page
	pageSize?: number; //pageSize
	Year?: Array<string>; //years
	Faculty?: Array<string>; //faculties
	Department?: Array<string>; //departments
	StudyProgram?: Array<string>; //studyPrograms
}

export const getAllStudentsV2 = (props: AllStudentsProps) => {
	const {
		search: namePattern,
		page,
		pageSize,
		Year: years,
		Faculty: faculties,
		Department: departments,
		StudyProgram: studyPrograms,
	} = props;

	return AuthAcademicUserApiFactory().apiAcademicUserGetAllStudentsGet({
		namePattern,
		years: arrayParamAjust(
			years?.map((e) => StudentYearEnum[e as keyof typeof StudentYearEnum])
		),
		faculties: arrayParamAjust(faculties),
		departments: arrayParamAjust(departments),
		studyPrograms: arrayParamAjust(studyPrograms),
		page,
		pageSize,
	});
};

export const getAllStudentsWhoAcceptGDPR = (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	years?: string[],
	faculties?: string[],
	departments?: string[],
	studyPrograms?: string[]
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAllStudentsForNewCompanyGet(
		{
			namePattern,
			years: arrayParamAjust(
				years?.map((e) => StudentYearEnum[e as keyof typeof StudentYearEnum])
			),
			faculties: arrayParamAjust(faculties),
			departments: arrayParamAjust(departments),
			studyPrograms: arrayParamAjust(studyPrograms),
			page,
			pageSize,
		}
	);
};

export const getAllStudentsWhoAcceptGDPRV2 = (props: AllStudentsProps) => {
	const {
		search: namePattern,
		page,
		pageSize,
		Year: years,
		Faculty: faculties,
		Department: departments,
		StudyProgram: studyPrograms,
	} = props;

	return AuthAcademicUserApiFactory().apiAcademicUserGetAllStudentsForNewCompanyGet(
		{
			namePattern,
			years: arrayParamAjust(
				years?.map((e) => StudentYearEnum[e as keyof typeof StudentYearEnum])
			),
			faculties: arrayParamAjust(faculties),
			departments: arrayParamAjust(departments),
			studyPrograms: arrayParamAjust(studyPrograms),
			page,
			pageSize,
		}
	);
};

export const getAllProfessors = (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	faculties?: string[],
	departments?: string[]
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAllProfessorsGet({
		namePattern,
		faculties: arrayParamAjust(faculties),
		departments: arrayParamAjust(departments),
		page,
		pageSize,
	});
};

export interface AllProfessorsProps {
	search?: string; //namePattern
	page?: number; //page
	pageSize?: number; //pageSize
	Faculty?: Array<string>; //faculties
	Department?: Array<string>; //departments
}

export const getAllProfessorsV2 = (props: AllProfessorsProps) => {
	const {
		search: namePattern,
		page,
		pageSize,
		Faculty: faculties,
		Department: departments,
	} = props;

	return AuthAcademicUserApiFactory().apiAcademicUserGetAllProfessorsGet({
		namePattern,
		faculties: arrayParamAjust(faculties),
		departments: arrayParamAjust(departments),
		page,
		pageSize,
	});
};

export const getAllAcademicUsersNonStudents = (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	faculties?: string[],
	departments?: string[]
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAcademicUsersNonStudentsGet(
		{
			namePattern,
			faculties: arrayParamAjust(faculties),
			departments: arrayParamAjust(departments),
			page,
			pageSize,
		}
	);
};

export interface AcademicUsersNonStudentsProps {
	search?: string; //namePattern
	page?: number; //page
	pageSize?: number; //pageSize
	faculties?: Array<string>;
	departments?: Array<string>;
}

export const getAllAcademicUsersNonStudentsV2 = (
	props: AcademicUsersNonStudentsProps
) => {
	const { search: namePattern, faculties, departments, page, pageSize } = props;

	return AuthAcademicUserApiFactory().apiAcademicUserGetAcademicUsersNonStudentsGet(
		{
			namePattern,
			faculties: arrayParamAjust(faculties),
			departments: arrayParamAjust(departments),
			page,
			pageSize,
		}
	);
};

export const getAllAcademicUsersByDepartment = (departmentId: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGet(
		{ departmentId }
	);
};

export const getProfessorById = (id: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetProfessorByIdIdGet({
		id,
	});
};

export const getProfessorsByIds = (ids: string[]) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetProfessorsByIdsGet({
		ids: arrayParamAjust(ids),
	});
};

export const getStudentById = (id: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetStudentByIdIdGet({
		id,
	});
};

export const updateProfessorInfo = async (
	id: string,
	academicUserUpdateDTO: AcademicUserUpdateDTO
) => {
	await AuthAcademicUserApiFactory().apiAcademicUserUpdateProfessorIdPut({
		id,
		academicUserUpdateDTO,
	});
};

export const updateStudentInfo = async (
	id: string,
	academicUserUpdateDTO: AcademicUserUpdateDTO
) => {
	await AuthAcademicUserApiFactory().apiAcademicUserUpdateStudentIdPut({
		id,
		academicUserUpdateDTO,
	});
};

export const updateStudentInfoAdmin = async (
	id: string,
	academicUserUpdateDTO: AcademicUserUpdateDTO
) => {
	await AuthAcademicUserApiFactory().apiAcademicUserUpdateStudentAdminIdPut({
		id,
		academicUserUpdateDTO,
	});
};

export const getStudentFilters = (language: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetStudentFiltersGet({
		language,
	});
};

export const getStudentFiltersForNewCompany = (language: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetStudentFiltersForCompanyGet(
		{ language }
	);
};

export const getProfessorFilters = (language: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetProfessorFiltersGet({
		language,
	});
};

export const updateAcademicUserRole = async (
	id: string,
	academicUserUpdateRoleDTO: AcademicUserUpdateRoleDTO
) => {
	await AuthAcademicUserApiFactory().apiAcademicUserUpdateAcademicUserRoleIdPut(
		{ id, academicUserUpdateRoleDTO }
	);
};

export const getRolesForUser = () => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetRolesForUserGet();
};

export const getGDPRDate = (id: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAcceptGDPRDateForStudentIdGet(
		{ id }
	);
};

export const acceptGDPRDate = (hasAcceptedPublicProfile: boolean) => {
	return AuthAcademicUserApiFactory().apiAcademicUserAcceptGDPRPut({
		hasAcceptedPublicProfile,
	});
};

export const getAvailableStudents = (educationCycle: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetStudentsFromDepartmentGet(
		{ educationCycle }
	);
};

export const getAvailablePracticeSupervisors = (companyId: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAvailablePracticeSupervisorsCompanyIdGet(
		{ companyId }
	);
};

export const getAllocatedPracticeSupervisors = (
	companyId: string,
	namePattern: string,
	page: number,
	pageSize: number
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAllocatedPracticeSupervisorsCompanyIdGet(
		{ companyId, namePattern, page, pageSize }
	);
};

export interface AllocatedPracticeSupervisorsProps {
	search?: string; //namePattern
	companyId: string;
	page?: number; //page
	pageSize?: number; //pageSize
}

export const getAllocatedPracticeSupervisorsV2 = (
	props: AllocatedPracticeSupervisorsProps
) => {
	const { search: namePattern, companyId, page, pageSize } = props;

	return AuthAcademicUserApiFactory().apiAcademicUserGetAllocatedPracticeSupervisorsCompanyIdGet(
		{ companyId, namePattern, page, pageSize }
	);
};

export const addSupervisedStudyProgram = async (
	supervisorId: string,
	studyProgramId: string,
	studyYear: StudentYearEnum
) => {
	await AuthAcademicUserApiFactory().apiAcademicUserAddSupervisedStudyProgramSupervisorIdPut(
		{
			supervisorId,
			supervisedStudyProgramDTO: { studyProgramId, studentYear: studyYear },
		}
	);
};

export const deleteSupervisedStudyProgram = async (
	supervisorId: string,
	studyProgramId: string,
	studyYear: StudentYearEnum
) => {
	await AuthAcademicUserApiFactory().apiAcademicUserDeleteSupervisedStudyProgramSupervisorIdDelete(
		{
			supervisorId,
			supervisedStudyProgramDTO: { studyProgramId, studentYear: studyYear },
		}
	);
};

export const toggleAcademicUserNotifications = async () => {
	await AuthAcademicUserApiFactory().apiAcademicUserToggleNotificationsPut();
};

export const toggleOverdueStudentPractice = async (
	id: string,
	year: StudentYearEnum
) => {
	await AuthAcademicUserApiFactory().apiAcademicUserToggleOverduePracticeIdPut({
		id,
		year,
	});
};

export const getAllSupervisors = async (namePattern: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserGetAllSupervisorsGet({
		namePattern,
	});
};

export const changeDepartmentForStudent = async (
	id: string,
	departmentId: string
) => {
	return AuthAcademicUserApiFactory().apiAcademicUserChangeDepartmentForStudentIdPut(
		{ id, body: departmentId }
	);
};

export const uploadStudentCvAdmin = async (studentId: string, file: Blob) => {
	await AuthAcademicUserApiFactory().apiAcademicUserUploadCvAdminStudentIdPut({
		studentId,
		file,
	});
};

export const uploadStudentCv = async (file: Blob) => {
	await AuthAcademicUserApiFactory().apiAcademicUserUploadCvPost({
		file,
	});
};

export const downloadStudentCv = (studentId: string) => {
	return AuthAcademicUserApiFactory().apiAcademicUserDownloadCvStudentIdGet({
		studentId,
	});
};
