import { useCallback, useEffect, useRef, useState } from "react";
import {
	EnrollmentStatus,
	ErrorCodes,
	InternsForEmployerDTO,
	InternshipDocumentState,
	InternshipEngagementType,
	InternshipType,
	InternType,
	PracticeCertificateSigningEnum,
	PracticeCertificateState,
	PracticeNotebookSigningEnum,
	PracticeNotebookState,
} from "../../Api";
import {
	deleteEnrollment,
	getInternsFiltersV2,
	getInternsForEmployerV2,
	updateInternshipEnrollmentTutor,
} from "../../Requests/internship-requests";
import {
	Table,
	Row,
	Menu,
	MenuProps,
	Modal,
	Spin,
	Typography,
	Dropdown,
	Upload,
	Select,
	Form,
	Input,
	Steps,
	Pagination,
	Button,
} from "antd";
import styles from "./Students.module.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
	studentIdSet,
	studentInternshipEnrollmentIdSet,
} from "../Student/StudentSlice";
import { useDispatch } from "react-redux";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	getUserId,
	useIsCompany,
	useIsDean,
	useIsDepartmentDirector,
	useIsFacultyAdmin,
	useIsProfessor,
	useIsStudyProgramCoordinator,
} from "../../utils/utilFunctions";
import {
	addPracticeNotebookSignature,
	downloadPracticeNotebook,
	generatePracticeNotebook,
	rejectPracticeNotebook,
} from "../../Requests/practice-notebook-requests";
import {
	downloadPracticeCertificate,
	generatePracticeCertificate,
	uploadSignedPracticeCertificate,
} from "../../Requests/practice-certificate-requests";
import { MimeType, downloadFile } from "../../utils/downloadUtils";
import { useQuery, useQueryClient } from "react-query";
import {
	getCompanyUserById,
	listAllCompanyUsersForCompany,
} from "../../Requests/company-users-requests";
import { approvedStudentsFilters } from "../../utils/constants";
import {
	getAllApplicationTypesCount,
	getApprovedStudentsFilters,
	getCompanyUser,
	getFacultiesDepartments,
	getInterns,
	getSpecificProfessor,
	listCompanyUsersForCompany,
} from "../../utils/reactQueriesConstants";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import {
	addInternshipDocumentSignature,
	generateInternshipDocument,
} from "../../Requests/internship-document-requests";
import CustomButton from "../../CustomComponents/CustomButton";
import CustomForm from "../../CustomComponents/CustomForm";
import { getErrorFromResponse } from "../../utils/responseUtils";
import { getDepartmentsForFaculties } from "../../Requests/department-requests";
import {
	changeDepartmentForStudent,
	downloadStudentCv,
	getProfessorById,
} from "../../Requests/academic-user-requests";
import { SelectDocumentTypeModal } from "../Views/DiplomaStudentsView/components/modals/SelectDocumentTypeModal/SelectDocumentTypeModal";
import Filters from "../Filters";
import useQueryFilters from "../../Hooks/useQueryFilters";
import { is } from "immer/dist/internal";

enum ActionKeyEnum {
	ValidatePracticeConvention,
	EditPracticeConvention,
	DownloadPracticeNotebook,
	ApprovePracticeNotebook,
	InvalidatePracticeNotebook,
	DownloadPracticeCertificate,
	UploadPracticeCertificate,
	EditPracticeCertificate,
	EditInternshipDocument,
	ValidateInternshipDocument,
	DownloadInternshipDocument,
	ViewLinkedInAccount,
	ViewCV,
	RemoveTraineeMessage,
	ChangeTutorMessage,
}

const { Option } = Select;

const MyStudentsTable = (props: {
	location: any;
	existingFilters?: any;
	oldSearchTerm?: any;
	currentPage?: any;
	pageSize?: any;
	activeTab?: any;
}) => {
	const { t, i18n } = useTranslation();
	const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
	const [modalTitle, setModalTitle] = useState("");
	const [modalText, setModalText] = useState("");
	const [documentModalTitle, setDocumentModalTitle] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isTutorModalVisible, setIsTutorModalVisible] = useState(false);
	const [isDocumentModalVisible, setIsDocumentModalVisible] = useState(false);
	const [isNotebookModalVisible, setIsNotebookModalVisible] = useState(false);
	const [practiceCertificateId, setPracticeCertificateId] = useState("");
	useEffect(() => {
		if (practiceCertificateId && uploadRef.current) {
			uploadRef.current.click();
		}
	}, [practiceCertificateId]);
	const uploadRef = useRef<HTMLButtonElement>(null);
	const [
		isPracticeCertificateModalVisible,
		setIsPracticeCertificateModalVisible,
	] = useState(false);
	const [handleFunction, setHandleFunction] = useState(() => () => {});
	const [spinning, setSpinning] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const { search } = useLocation();
	const isCompany = useIsCompany();
	const isProfessor = useIsProfessor();
	const isDean = useIsDean();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isStudyProgramCoordinator = useIsStudyProgramCoordinator();
	const isDepartmentDirector = useIsDepartmentDirector();
	const [activeTab, setActiveTab] = useState("1");
	const [documentType, setDocumentType] = useState("PDF");
	const [selectedTutor, setSelectedTutor] = useState("");
	const [enrollmentId, setEnrollmentId] = useState("");
	const [notebookState, setNotebookState] = useState("");
	const [practiceCertificateState, setPracticeCertificateState] = useState("");
	const [defaultDepartment, setDefaultDepartment] = useState("");
	const [form] = Form.useForm<{
		documentType: string;
		department: string;
	}>();
	const [rejectForm] = Form.useForm<{
		rejectComment: string;
	}>();
	const query = useQueryFilters({
		initialValues: {
			tab: activeTab,
		},
	});

	let locale = {
		emptyText: t("tableText.noStudents"),
	};

	const currentUserId = getUserId();

	const returnAction = t("practiceCertificate.uploadedFile");

	useEffect(() => {
		const url = new URLSearchParams(search);
		if (!url) return;

		for (const [key, value] of url) {
			if (key === "tab") {
				setActiveTab(value);
				break;
			}
		}
	}, []);

	const uploadSucceeded = () => {
		openNotification(
			t("practiceCertificate.uploadedFile"),
			t("practiceCertificate.uploadSuccess"),
			NOTIFICATION_TYPES.SUCCESS
		);
	};

	const uploadFailed = (_err: any) => {
		openNotification(
			t("practiceCertificate.error"),
			t("practiceCertificate.uploadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const showNotebookInfo = (record: any) => {
		setNotebookState(record.practiceNotebookStatus);
		setIsNotebookModalVisible(true);
	};

	const showPracticeCertificateInfo = (record: any) => {
		setPracticeCertificateState(record.practiceCertificateStatus);
		setIsPracticeCertificateModalVisible(true);
	};

	const currentStatusNotebook = () => {
		if (notebookState === PracticeNotebookState.NotGenerated) {
			return 0;
		} else if (notebookState === PracticeNotebookState.InProgress) {
			return 1;
		} else if (notebookState === PracticeNotebookState.PendingTutorSignature) {
			return 2;
		} else if (
			notebookState === PracticeNotebookState.PendingInternshipAdminValidation
		) {
			return 3;
		} else if (notebookState === PracticeNotebookState.Finalized) {
			return 4;
		}
	};

	const currentStatusPracticeCertificate = () => {
		if (practiceCertificateState === PracticeCertificateState.NotGenerated) {
			return 0;
		} else if (
			practiceCertificateState === PracticeCertificateState.InProgress
		) {
			return 1;
		} else if (
			practiceCertificateState ===
			PracticeCertificateState.PendingPracticeRepresentativeSignature
		) {
			return 2;
		} else if (
			practiceCertificateState ===
			PracticeCertificateState.PendingInternshipAdminValidation
		) {
			return 3;
		} else if (
			practiceCertificateState === PracticeCertificateState.Finalized
		) {
			return 4;
		}
	};

	const handleNotebookModalCancel = () => {
		setIsNotebookModalVisible(false);
	};

	const handlePracticeCertificateModalCancel = () => {
		setIsPracticeCertificateModalVisible(false);
	};

	const goToEditCertificatePage = (enrollmentId: any) => {
		dispatch(studentInternshipEnrollmentIdSet(enrollmentId));
		history.push("/editare-atestat-practica");
	};

	const goToEditDocumentPage = (enrollmentId: any) => {
		dispatch(studentInternshipEnrollmentIdSet(enrollmentId));
		history.push("/editare-document-propunere", props);
	};

	const openNotificationFetchError = (ex: any) => {
		if (ex.status) {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.serverFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.networkFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const { data: companyUser } = useQuery(
		[getCompanyUser, isCompany],
		async () => (isCompany ? getCompanyUserById(getUserId()) : null),
		{
			onError: openNotificationFetchError,
		}
	);

	const { data: companyUsers } = useQuery(
		[listCompanyUsersForCompany, isCompany, companyUser],
		async () =>
			isCompany ? listAllCompanyUsersForCompany(companyUser?.companyId!) : [],
		{
			onError: openNotificationFetchError,
		}
	);

	const downloadFailPracticeNotebook = (_err: any) => {
		openNotification(
			t("students.downloadPracticeNotebook"),
			t("practiceNotebook.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const downloadNotebook = (record: InternsForEmployerDTO) => {
		if (
			record.practiceNotebookLastSigner ===
				PracticeNotebookSigningEnum.Student &&
			record.practiceNotebookStatus !==
				PracticeNotebookState.PendingTutorSignature
		) {
			downloadFile(
				downloadPracticeNotebook(record.practiceNotebookLastSignatureId!),
				t("students.practiceNotebook") + "_" + record.name
			).catch(downloadFailPracticeNotebook);
		} else {
			downloadFile(
				generatePracticeNotebook(record.practiceNotebookId!),
				t("students.practiceNotebook") + "_" + record.name
			).catch(downloadFailPracticeNotebook);
		}
	};

	const signNotebook = (id: any) => {
		setSpinning(true);

		addPracticeNotebookSignature(id)
			.then(async () => {
				await queryClient.invalidateQueries(getInterns);
				await queryClient.invalidateQueries(getApprovedStudentsFilters);

				openNotification(
					t("students.approvePracticeNotebook"),
					t("students.successApprovePracticeNotebookMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.approvePracticeNotebook"),
					t("students.errorApprovePracticeNotebookMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const signInternshipDocument = (id: any) => {
		addInternshipDocumentSignature(id)
			.then(async () => {
				await queryClient.invalidateQueries("getInterns");
				await queryClient.invalidateQueries("getFilters");

				openNotification(
					t("students.approveInternshipDocument"),
					t("students.successApproveInternshipDocumentMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.approveInternshipDocument"),
					t("students.errorApproveInternshipDocumentMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const downloadFailPracticeCertificate = (_err: any) => {
		openNotification(
			t("practiceCertificate.error"),
			t("practiceCertificate.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const downloadCertificate = (record: InternsForEmployerDTO) => {
		if (
			record.practiceCertificateLastSigner ===
				PracticeCertificateSigningEnum.PracticeRepresentative ||
			record.practiceCertificateLastSigner ===
				PracticeCertificateSigningEnum.Student
		) {
			downloadFile(
				downloadPracticeCertificate(record.practiceCertificateLastSignatureId!),
				t("students.practiceCertificate") + "_" + record.name
			).catch(downloadFailPracticeCertificate);
		} else {
			downloadFile(
				generatePracticeCertificate(record.practiceCertificateId!),
				t("students.practiceCertificate") + "_" + record.name
			).catch(downloadFailPracticeCertificate);
		}
	};

	const onDepartmentChange = (event: string) => {
		setDefaultDepartment(event);
	};

	const downloadInternshipDocument = async (
		enrollment: InternsForEmployerDTO
	) => {
		let { documentType, department } = form.getFieldsValue();
		if (enrollment.departmentId !== department) {
			await changeDepartmentForStudent(enrollment.id!, department);
		}
		if (
			enrollment.internshipDocumentStatus ===
				InternshipDocumentState.PendingCoordinatorSignature ||
			enrollment.internshipDocumentStatus ===
				InternshipDocumentState.PendingStudyProgramCoordinatorSignature ||
			enrollment.internshipDocumentStatus ===
				InternshipDocumentState.PendingDepartmentDirectorSignature ||
			enrollment.internshipDocumentStatus ===
				InternshipDocumentState.PendingDeanSignature ||
			enrollment.internshipType === InternshipType.ByProfessor
		) {
			downloadFile(
				generateInternshipDocument(
					enrollment.internshipDocumentId!,
					documentType === "PDF" ? MimeType.pdf : MimeType.docx
				),
				t("students.internshipDocument"),
				documentType === "PDF" ? MimeType.pdf : MimeType.docx
			).catch(downloadFailPracticeCertificate);
		} else {
			downloadFile(
				generateInternshipDocument(
					enrollment.internshipDocumentLastSignatureId!,
					documentType === "PDF" ? MimeType.pdf : MimeType.docx
				),
				t("students.internshipDocument"),
				documentType === "PDF" ? MimeType.pdf : MimeType.docx
			).catch(downloadFailPracticeCertificate);
		}
	};

	const uploadSignedCertificate = (id: string, file: Blob) => {
		return uploadSignedPracticeCertificate(id, file).then(async () => {
			await queryClient.invalidateQueries(getInterns);
			await queryClient.invalidateQueries(getApprovedStudentsFilters);
		});
	};

	const removeTrainee = (item: any) => {
		setSpinning(true);

		deleteEnrollment(item.internshipEnrollmentId)
			.then(async () => {
				await queryClient.invalidateQueries(getInterns);
				await queryClient.invalidateQueries(getApprovedStudentsFilters);
				await queryClient.invalidateQueries(getAllApplicationTypesCount);
				openNotification(
					t("students.removeTrainee"),
					t("students.successRemoveTraineeMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.removeTrainee"),
					t("students.errorRemoveTraineeMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const updateTutor = (enrollmentId: string, tutorId: string) => {
		setSpinning(true);

		updateInternshipEnrollmentTutor(enrollmentId, tutorId)
			.then(async () => {
				openNotification(
					t("students.changeTutor"),
					t("students.successChangeTutorMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(async (error) => {
				const errorMessage = await getErrorFromResponse(error);

				let notificationMessage;

				switch (errorMessage?.code) {
					case ErrorCodes.InternshipEnrollmentNotFound:
						notificationMessage = t("internships.enrollmentNotFound");
						break;
					case ErrorCodes.UserNotFound:
						notificationMessage = t("students.tutorNotFound");
						break;
					case ErrorCodes.Forbidden:
						notificationMessage = t("students.noPermissions");
						break;
					case ErrorCodes.InvalidInternshipType:
						notificationMessage = t("students.invalidInternshipType");
						break;
					case ErrorCodes.InvalidTutor:
						notificationMessage = t("students.invalidTutor");
						break;
					default:
						notificationMessage = t("students.errorChangeTutorMessage");
						break;
				}

				openNotification(
					t("students.changeTutor"),
					notificationMessage,
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsTutorModalVisible(false);
			});
	};

	const rejectNotebook = (id: any) => {
		setSpinning(true);

		rejectPracticeNotebook(id, rejectForm.getFieldsValue().rejectComment)
			.then(async () => {
				await queryClient.invalidateQueries(getInterns);

				openNotification(
					t("students.rejectPracticeNotebook"),
					t("students.successRejectPracticeNotebookMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.rejectPracticeNotebook"),
					t("students.errorRejectPracticeNotebookMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const getActions = (
		record: InternsForEmployerDTO
	): ItemType[] | undefined => {
		let actions: MenuProps["items"] = [];

		actions.push(
			{
				label: t("students.cvUrl"),
				key: ActionKeyEnum.ViewCV + "StudentsTables",
				icon: (
					<FontAwesomeIcon
						icon={solid("download")}
						style={{ fontSize: "15px" }}
					/>
				),
			},
			{
				label: t("students.viewLinkedIn"),
				key: ActionKeyEnum.ViewLinkedInAccount + "StudentsTables",
				icon: (
					<FontAwesomeIcon
						icon={solid("arrow-right")}
						style={{ fontSize: "15px" }}
					/>
				),
			}
		);

		// practice certificate actions
		if (
			record.internshipEngagementType === InternshipEngagementType.Internship
		) {
			if (
				record.practiceCertificateStatus ===
					PracticeCertificateState.NotGenerated ||
				record.practiceCertificateStatus === PracticeCertificateState.InProgress
			) {
				if (
					record.recruiterStatus === EnrollmentStatus.Accepted &&
					record.studentStatus === EnrollmentStatus.Accepted
					// && record.internshipTutorId === getUserId()
				) {
					actions.push({
						label: t("practiceCertificate.fillPracticeCertificate"),
						key: ActionKeyEnum.EditPracticeCertificate + "StudentsTables",
						icon: (
							<FontAwesomeIcon
								icon={solid("pencil")}
								style={{ fontSize: "15px" }}
							/>
						),
					});
				}
			} else {
				actions.push({
					label: t("students.downloadPracticeCertificate"),
					key: ActionKeyEnum.DownloadPracticeCertificate + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("download")}
							style={{ fontSize: "15px" }}
						/>
					),
				});

				if (
					(record.practiceCertificateStatus ===
						PracticeCertificateState.PendingPracticeRepresentativeSignature ||
						record.practiceCertificateStatus ===
							PracticeCertificateState.PendingInternshipAdminValidation) &&
					isCompany
					// && (companyUser?.isRepresentative || companyUser?.isAdmin)
				) {
					actions.push({
						label: t("students.uploadSignedPracticeCertificate"),
						key: ActionKeyEnum.UploadPracticeCertificate + "StudentsTables",
						icon: (
							<FontAwesomeIcon
								icon={solid("upload")}
								style={{ fontSize: "15px" }}
							/>
						),
					});
				}
			}
		}

		// practice notebook actions
		if (
			record.internshipEngagementType === InternshipEngagementType.Internship
		) {
			if (
				record.practiceNotebookStatus !== PracticeNotebookState.NotGenerated
			) {
				actions.push({
					label: t("students.downloadPracticeNotebook"),
					key: ActionKeyEnum.DownloadPracticeNotebook + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("download")}
							style={{ fontSize: "15px" }}
						/>
					),
				});

				if (
					record.practiceNotebookStatus ===
					PracticeNotebookState.PendingTutorSignature
				) {
					// if (record.internshipTutorId === getUserId()) {
					actions.push({
						label: t("students.approvePracticeNotebook"),
						key: ActionKeyEnum.ApprovePracticeNotebook + "StudentsTables",
						icon: (
							<FontAwesomeIcon
								icon={solid("circle-check")}
								style={{ fontSize: "15px" }}
							/>
						),
					});
					actions.push({
						label: t("students.rejectPracticeNotebook"),
						key: ActionKeyEnum.InvalidatePracticeNotebook + "StudentsTables",
						icon: (
							<FontAwesomeIcon
								icon={solid("ban")}
								style={{ fontSize: "15px" }}
							/>
						),
					});
					// }
				}
			}
		}

		if (
			((isProfessor && record.internshipEmployerId === getUserId()) ||
				(isCompany &&
					record.internshipEmployerId === companyUser?.companyId)) &&
			record.recruiterStatus === EnrollmentStatus.Accepted &&
			record.studentStatus === EnrollmentStatus.Accepted
		) {
			if (isCompany && record.internshipEmployerId === companyUser?.companyId) {
				actions.push({
					label: t("students.changeTutor"),
					key: ActionKeyEnum.ChangeTutorMessage + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("user-pen")}
							style={{ fontSize: "15px" }}
						/>
					),
				});
			}

			if (!record.hasGeneratedPracticeConvention) {
				actions.push({
					label: t("students.removeTrainee"),
					key: ActionKeyEnum.RemoveTraineeMessage + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("trash")}
							style={{ fontSize: "15px" }}
						/>
					),
				});
			}
		}

		if (
			(record.internshipEngagementType ===
				InternshipEngagementType.BachelorsInternship ||
				record.internshipEngagementType ===
					InternshipEngagementType.MastersInternship) &&
			record.internshipDocumentStatus !==
				InternshipDocumentState.NotGenerated &&
			record.internshipDocumentStatus !== InternshipDocumentState.InProgress
		) {
			if (
				isProfessor ||
				isDean ||
				isStudyProgramCoordinator ||
				isDepartmentDirector
			) {
				actions.push({
					label: t("students.downloadInternshipDocument"),
					key: ActionKeyEnum.DownloadInternshipDocument + "StudentsTables",
					icon: (
						<FontAwesomeIcon
							icon={solid("download")}
							style={{ fontSize: "15px" }}
						/>
					),
				});

				if (
					(record.internshipDocumentStatus ===
						InternshipDocumentState.PendingCoordinatorSignature &&
						record.internshipEmployerId === getUserId()) ||
					(record.internshipDocumentStatus ===
						InternshipDocumentState.PendingStudyProgramCoordinatorSignature &&
						record.documentSigningStudyProgramCoordId === getUserId()) ||
					(record.internshipDocumentStatus ===
						InternshipDocumentState.PendingDepartmentDirectorSignature &&
						record.documentSigningDepartmentDirectorId === getUserId()) ||
					(record.internshipDocumentStatus ===
						InternshipDocumentState.PendingDeanSignature &&
						record.documentSigningDeanId === getUserId())
				) {
					actions.push({
						label: t("students.editInternshipDocument"),
						key: ActionKeyEnum.EditInternshipDocument + "StudentsTables",
						icon: (
							<FontAwesomeIcon
								icon={solid("pencil")}
								style={{ fontSize: "15px" }}
							/>
						),
					});

					actions.push({
						label: t("students.validateInternshipDocument"),
						key: ActionKeyEnum.ValidateInternshipDocument + "StudentsTables",
						icon: (
							<FontAwesomeIcon
								icon={solid("circle-check")}
								style={{ fontSize: "15px" }}
							/>
						),
					});
				}
			}
		}

		return actions;
	};

	const downloadFail = (_err: any) => {
		openNotification(
			t("universityAccord.error"),
			t("universityAccord.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const onMenuClick = (e: any, record: InternsForEmployerDTO) => {
		if (e.key == ActionKeyEnum.ViewCV + "StudentsTables") {
			if (record.cvUrl) {
				window.location.replace(record.cvUrl!);
			} else if (record.cvFileName) {
				downloadFile(
					downloadStudentCv(record.id!),
					t("documents.Cv") + "_" + record.name
				).catch(downloadFail);
			}
		} else if (e.key == ActionKeyEnum.ViewLinkedInAccount + "StudentsTables") {
			window.location.replace(record.linkedinUrl!);
		} else if (
			e.key ==
			ActionKeyEnum.DownloadPracticeNotebook + "StudentsTables"
		) {
			downloadNotebook(record);
		} else if (
			e.key ==
			ActionKeyEnum.ApprovePracticeNotebook + "StudentsTables"
		) {
			setModalTitle(t("students.approvePracticeNotebook"));
			setModalText(
				t("students.approveNotebookMessage") +
					record.name +
					t("students.approveNotebookMessage2") +
					record.internshipName +
					"?"
			);
			setIsModalVisible(true);
			setHandleFunction(() => () => signNotebook(record.practiceNotebookId));
		} else if (
			e.key ==
			ActionKeyEnum.InvalidatePracticeNotebook + "StudentsTables"
		) {
			setModalTitle(t("students.rejectPracticeNotebook"));
			setModalText(
				t("students.rejectNotebookMessage") +
					record.name +
					t("students.rejectNotebookMessage2") +
					record.internshipName +
					"? " +
					t("students.rejectComment")
			);
			setIsModalVisible(true);
			setHandleFunction(() => () => rejectNotebook(record.practiceNotebookId));
		} else if (
			e.key ==
			ActionKeyEnum.DownloadPracticeCertificate + "StudentsTables"
		) {
			setHandleFunction(() => downloadCertificate(record));
		} else if (
			e.key ==
			ActionKeyEnum.UploadPracticeCertificate + "StudentsTables"
		) {
			setHandleFunction(() => {
				setPracticeCertificateId(record.practiceCertificateId!);
			});
		} else if (
			e.key ==
			ActionKeyEnum.EditPracticeCertificate + "StudentsTables"
		) {
			setHandleFunction(() =>
				goToEditCertificatePage(record.internshipEnrollmentId)
			);
		} else if (
			e.key ==
			ActionKeyEnum.EditInternshipDocument + "StudentsTables"
		) {
			setHandleFunction(() =>
				goToEditDocumentPage(record.internshipEnrollmentId)
			);
		} else if (
			e.key ==
			ActionKeyEnum.DownloadInternshipDocument + "StudentsTables"
		) {
			setDefaultDepartment(record.departmentId!);
			setDocumentModalTitle(t("students.downloadInternshipDocument"));
			setHandleFunction(() => () => downloadInternshipDocument(record));
			setIsDocumentModalVisible(true);
		} else if (
			e.key ==
			ActionKeyEnum.ValidateInternshipDocument + "StudentsTables"
		) {
			setHandleFunction(() =>
				signInternshipDocument(record.internshipDocumentId)
			);
		} else if (e.key == ActionKeyEnum.RemoveTraineeMessage + "StudentsTables") {
			setModalTitle(t("students.removeTrainee"));
			setModalText(
				t("students.removeTraineeMessage") +
					record.name +
					t("students.removeTraineeMessage2")
			);
			setIsModalVisible(true);
			setHandleFunction(() => () => removeTrainee(record));
		} else if (e.key == ActionKeyEnum.ChangeTutorMessage + "StudentsTables") {
			setEnrollmentId(record.internshipEnrollmentId!);
			setSelectedTutor(record.internshipTutorId!);
			setIsTutorModalVisible(true);
		}
	};

	const columns = [
		{
			title: t("account.name"),
			dataIndex: "name",
			fixed: "left" as "left",
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.id)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("applicants.internshipName"),
			dataIndex: "internshipName",
			key: "internshipName",
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.internshipName || !b.internshipName) return -1;
			// 	return a.internshipName.localeCompare(b.internshipName);
			// },
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname:
							"/propunere/" +
							(record.internshipType === InternshipType.ByProfessor
								? record.professorInternshipId
								: record.companyInternshipId),
						search: "?type=" + record.internshipType,
						state: {
							origin: props.location.pathname,
							// filters: checkedKeys,
							// searchTerm: searchTerm,
							// currentPage: currentPage,
						},
					}}
				>
					{record.internshipName}
				</Link>
			),
		},
		{
			title: t("reports.employerName"),
			dataIndex: "internshipEmployerName",
			key: "internshipEmployerName",
			hidden: !isFacultyAdmin,
			width: 200,
		},
		{
			title: t("students.studyProgram"),
			dataIndex: "studyProgram",
			key: "studyProgram",
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.studyProgram || !b.studyProgram) return -1;
			// 	return a.studyProgram.localeCompare(b.studyProgram);
			// },
			hidden: window.innerWidth < 550,
		},
		{
			title: t("students.year"),
			dataIndex: "year",
			key: "year",
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.year || !b.year) return -1;
			// 	return a.year.localeCompare(b.year);
			// },
			hidden: window.innerWidth < 550,
		},
		{
			title: t("internships.type"),
			dataIndex: "internshipEngagementType",
			key: "internshipEngagementType",
			hidden: window.innerWidth < 650,
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.internshipEngagementType || !b.internshipEngagementType)
			// 		return -1;
			// 	return a.internshipEngagementType.localeCompare(
			// 		b.internshipEngagementType
			// 	);
			// },
			render: (_text: string | undefined, record: any) =>
				t("internships.filters." + record.internshipEngagementType),
		},
		{
			title: t("students.internshipDocumentStatus"),
			dataIndex: "internshipDocumentStatus",
			key: "internshipDocumentStatus",
			hidden: window.innerWidth < 1200,
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.internshipDocumentStatus || !b.internshipDocumentStatus)
			// 		return -1;
			// 	return a.internshipDocumentStatus.localeCompare(
			// 		b.internshipDocumentStatus
			// 	);
			// },
			render: (_text: string | undefined, record: any) =>
				t(
					"students.internshipDocumentStatuses." +
						record.internshipDocumentStatus
				),
		},
		{
			title: t("students.practiceNotebookStatus"),
			dataIndex: "practiceNotebookStatus",
			key: "practiceNotebookStatus",
			hidden: window.innerWidth < 1200,
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.practiceNotebookStatus || !b.practiceNotebookStatus) return -1;
			// 	return a.practiceNotebookStatus.localeCompare(b.practiceNotebookStatus);
			// },
			render: (_text: string | undefined, record: any) => {
				return record.practiceNotebookStatus ===
					PracticeNotebookState.NotApplicable ? (
					t("internships.filters." + record.practiceNotebookStatus)
				) : (
					<div
						onClick={() => showNotebookInfo(record)}
						style={{ cursor: "pointer" }}
					>
						{t("internships.filters." + record.practiceNotebookStatus)}
					</div>
				);
			},
		},
		{
			title: t("students.practiceCertificateStatus"),
			dataIndex: "practiceCertificateStatus",
			key: "practiceCertificateStatus",
			hidden: window.innerWidth < 1200,
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.practiceCertificateStatus || !b.practiceCertificateStatus)
			// 		return -1;
			// 	return a.practiceCertificateStatus.localeCompare(
			// 		b.practiceCertificateStatus
			// 	);
			// },
			render: (_text: string | undefined, record: any) => {
				return record.practiceCertificateStatus ===
					PracticeCertificateState.NotApplicable ? (
					t("internships.filters." + record.practiceCertificateStatus)
				) : (
					<div
						onClick={() => showPracticeCertificateInfo(record)}
						style={{ cursor: "pointer" }}
					>
						{t("internships.filters." + record.practiceCertificateStatus)}
					</div>
				);
			},
		},
		{
			title: t("students.actions"),
			dataIndex: "actions",
			fixed: "right" as "right",
			width: 85,
			render: (_text: string | undefined, record: any) => (
				<div>
					<Dropdown
						placement="bottomLeft"
						overlay={
							<Menu
								onClick={(e) => onMenuClick(e, record)}
								items={getActions(record)}
							/>
						}
					>
						<FontAwesomeIcon
							icon={solid("bars")}
							style={{
								cursor: "pointer",
								height: "1rem",
								padding: "6px 0px",
								marginLeft: "13px",
							}}
						/>
					</Dropdown>
				</div>
			),
		},
	].filter((item) => !item.hidden);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.failedUserGet"),
			t("usersText.errorTexts.serverFailedDescription"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const queryClient = useQueryClient();
	const { data, isLoading: loading } = useQuery(
		[getInterns, query.filters],
		() => {
			return getInternsForEmployerV2({
				type: InternType.Trainee,
				...query.filters,
			});
		},
		{
			onError: openGetErrorNotification,
		}
	);

	const { data: currentUser } = useQuery(
		[getSpecificProfessor, currentUserId],
		() => {
			return getProfessorById(currentUserId);
		}
	);

	const { data: departments } = useQuery(
		[getFacultiesDepartments, currentUser],
		() => {
			if (currentUser) {
				return getDepartmentsForFaculties([currentUser?.faculty?.id!]);
			}
		}
	);

	useEffect(() => {
		//props.location.state is set with the internshipId when the user clicks View Applicants in Internship Component
		//we'll use this id for filtering
		//the next line clears the id from props.location.state
		window.history.replaceState({}, document.title);
	}, [query.filters, i18n.language]);

	const getAllKeys = (filterItems: any[]) => {
		const keys: any[] = [];

		filterItems.forEach((filter) => {
			keys.push(filter.key);

			filter.children.forEach((filterItem: any) => {
				keys.push(filterItem.key.split("/")[1]);
			});
		});

		return keys;
	};

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.error"),
			t("usersText.errorTexts.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: filters } = useQuery(
		[
			getApprovedStudentsFilters,
			approvedStudentsFilters,
			i18n.language,
			query.filters,
		],
		() => {
			return getInternsFiltersV2({
				filtersList: approvedStudentsFilters,
				language: i18n.language,
				type: InternType.Trainee,
				...query.filters,
			});
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
		}
	);

	const handleClose = () => {
		rejectForm.setFieldsValue({ rejectComment: "" });
		setIsModalVisible(false);
		setIsTutorModalVisible(false);
	};

	const onChange = (event: any) => {
		setDocumentType(event);
	};

	const handleDocumentModalClose = () => {
		setIsDocumentModalVisible(false);
		setDocumentType("PDF");
	};

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update({ tab: [activeTab], ...values }, true);
		},
		[query]
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="px-4 pb-10 flex flex-col">
			<Filters
				filters={filters}
				searchFields={[{ name: "search", label: t("search.byName") }]}
				onUpdate={onUpdate}
				hasSort={false}
			/>

			<div className="flex flex-col gap-10">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{data?.totalCount} {t("filtering.results")}
					</Typography.Title>
					{!loading && (
						<Pagination
							defaultCurrent={data?.page}
							defaultPageSize={data?.pageSize}
							total={data?.totalCount}
							onChange={onPageUpdate}
							locale={{ items_per_page: t("pagination") }}
						/>
					)}
				</div>
				<Table
					locale={locale}
					columns={columns}
					dataSource={data?.data ?? []}
					pagination={false}
					scroll={{ x: "calc(700px + 50%)", y: 420 }}
					rowKey={(record) => record.id!}
				/>
			</div>
			<Modal
				open={isModalVisible}
				onOk={handleFunction}
				onCancel={handleClose}
				title={modalTitle}
				width={
					window.innerWidth > 800
						? "35%"
						: window.innerWidth < 450
						? "80%"
						: "70%"
				}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={handleFunction}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<div>
						<Typography>{modalText}</Typography>
						{(modalTitle === t("students.rejectPracticeNotebook") ||
							modalTitle === t("students.rejectPracticeCertificate")) && (
							<Form form={rejectForm} style={{ marginBottom: "0px" }}>
								<Form.Item
									name="rejectComment"
									rules={[
										{
											required: true,
											message: t("students.requiredField"),
										},
									]}
								>
									<Input
										placeholder={t("students.rejectCommentPlaceholder")}
										style={{ marginTop: "10px" }}
									/>
								</Form.Item>
							</Form>
						)}
					</div>
				</Spin>
			</Modal>
			<Modal
				open={isTutorModalVisible}
				onOk={() => updateTutor(enrollmentId, selectedTutor)}
				onCancel={handleClose}
				title={t("students.changeTutor")}
				width={
					window.innerWidth > 800
						? "35%"
						: window.innerWidth < 450
						? "80%"
						: "70%"
				}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={() => updateTutor(enrollmentId, selectedTutor)}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<p>{t("students.selectTutor")}</p>
					<Row>
						<Select
							defaultValue={selectedTutor}
							onChange={(event) => setSelectedTutor(event)}
							style={{ width: "18rem" }}
						>
							{companyUsers?.map((item, index) => (
								<Option value={item.id} key={"user" + index}>
									{item.name}
								</Option>
							))}
						</Select>
					</Row>
				</Spin>
			</Modal>
			<div className={styles.modalBtnContainer}>
				<Upload
					accept=".pdf"
					action={(uploaded) => {
						uploadSignedCertificate(practiceCertificateId, uploaded)
							.then(() => {
								setPracticeCertificateId("");
								return uploadSucceeded();
							})
							.catch((error) => {
								setPracticeCertificateId("");
								return uploadFailed(error);
							});
						return returnAction;
					}}
					showUploadList={false}
					multiple={false}
					customRequest={({ onSuccess }) => {
						onSuccess && onSuccess("");
					}}
				>
					<Button
						ref={uploadRef}
						style={{
							display: "none",
						}}
					></Button>
				</Upload>
			</div>
			<SelectDocumentTypeModal
				visible={isDocumentModalVisible}
				title={t("students.downloadInternshipDocument")}
				form={form}
				defaultValue={documentType}
				departments={departments!}
				defaultDepartmentValue={defaultDepartment}
				onDepartmentChange={onDepartmentChange}
				onOk={handleFunction}
				onCancel={handleDocumentModalClose}
				onClose={handleDocumentModalClose}
				onSave={handleFunction}
				onChange={onChange}
			/>
			<Modal
				open={isNotebookModalVisible}
				onCancel={handleNotebookModalCancel}
				title={t("students.practiceNotebook")}
				width={
					window.innerWidth > 800
						? "35%"
						: window.innerWidth < 450
						? "80%"
						: "70%"
				}
				footer={null}
			>
				<Steps direction="vertical" current={currentStatusNotebook()}>
					<Steps.Step
						title={t("students.internshipDocumentStatuses.NotGenerated")}
						description={t("practiceNotebook.description.NotGeneratedCompany")}
					/>
					<Steps.Step
						title={t("students.internshipDocumentStatuses.InProgress")}
						description={t("practiceNotebook.description.InProgressCompany")}
					/>
					<Steps.Step
						title={t("internships.filters.PendingTutorSignature")}
						description={t(
							"practiceNotebook.description.PendingTutorSignatureCompany"
						)}
					/>
					<Steps.Step
						title={t("internships.filters.PendingInternshipAdminValidation")}
						description={
							t(
								"practiceNotebook.description.PendingInternshipAdminValidation1"
							) +
							t(
								"practiceNotebook.description.PendingInternshipAdminValidation2"
							)
						}
					/>
					<Steps.Step
						title={t("internships.filters.Finalized")}
						description={t("practiceNotebook.description.Finalized")}
					/>
				</Steps>
			</Modal>
			<Modal
				open={isPracticeCertificateModalVisible}
				onCancel={handlePracticeCertificateModalCancel}
				title={t("students.practiceCertificateStatus")}
				width={
					window.innerWidth > 800
						? "35%"
						: window.innerWidth < 450
						? "80%"
						: "70%"
				}
				footer={null}
			>
				<Steps
					direction="vertical"
					current={currentStatusPracticeCertificate()}
				>
					<Steps.Step
						title={t("students.internshipDocumentStatuses.NotGenerated")}
						description= {isCompany ? 
							t("practiceCertificate.description.NotGeneratedCompany") :
							t("practiceCertificate.description.NotGeneratedProfessor")}
					/>
					<Steps.Step
						title={t("students.internshipDocumentStatuses.InProgress")}
						description= {isCompany ? 
							t("practiceCertificate.description.InProgressCompany"):
							t("practiceCertificate.description.InProgressProfessor")}
					/>
					{(!isProfessor) &&<Steps.Step
						title={t(
							"internships.filters.PendingPracticeRepresentativeSignature"
						)}
						description={t(
							"practiceCertificate.description.PendingPracticeRepresentativeSignatureCompany"
						)}
					/>}
					<Steps.Step
						title={t("internships.filters.PendingInternshipAdminValidation")}
						description={ isCompany ?	
							t(
								"practiceCertificate.description.PendingInternshipAdminValidation1"
							) +
							t(
								"practiceCertificate.description.PendingInternshipAdminValidation2"
							) :
							t(
								"practiceCertificate.description.PendingInternshipAdminValidationProfessor"
							)
						}
					/>
					<Steps.Step
						title={t("internships.filters.Finalized")}
						description={t("practiceCertificate.description.Finalized")}
					/>
				</Steps>
			</Modal>
		</div>
	);
};

export default MyStudentsTable;
